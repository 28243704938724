import LesserEval from 'piivo-poster-engine/src/util/lesserEval';
import Vue from 'vue';
import { Route } from 'vue-router';

import { ensureArray } from '../../../utils/array';
import { AttributeValues, Form } from '../api/attributes';
import { AttributeTypes } from '../constants';

interface IterableForm {
  inputGroups?: Form.AttributeGroup[];
  attributeGroups?: Form.AttributeGroup[];
}

/**
 * Finds an attribute in the groups
 *
 * @param groups - groups of attributes
 * @param predicate - the attribute predicate
 */
export function findAttributeInGroup(
  groups: Form.AttributeGroup[] | undefined | null,
  predicate: (attr: Form.Attribute, group: Form.AttributeGroup) => boolean
): Form.Attribute | null {
  if (!groups) {
    return null;
  }

  for (const group of groups) {
    if (!group.attributes) {
      continue;
    }

    for (const attribute of group.attributes) {
      if (predicate(attribute, group)) {
        return attribute;
      }
    }
  }

  return null;
}

/**
 * Finds the attribute in the form
 *
 * @param form - Form to iterate
 * @param predicate - the attribute predicate
 */
export function findAttributeInForm(
  form: IterableForm | undefined | null,
  predicate: (attr: Form.Attribute, group: Form.AttributeGroup) => boolean
): Form.Attribute | null {
  return (
    findAttributeInGroup(form?.inputGroups ?? null, predicate) ??
    findAttributeInGroup(form?.attributeGroups ?? null, predicate) ??
    null
  );
}

/**
 * Iterates on the attributes of the group
 *
 * @param groups - groups of attributes
 * @param attributeCb - the attribute callback
 */
export function iterateAttrGroup(
  groups: Pick<Form.AttributeGroup, 'attributes'>[] | undefined | null,
  attributeCb: (attr: Form.Attribute) => void
): void {
  groups?.forEach((group) => {
    group?.attributes?.forEach((attribute) => {
      if (attribute) {
        attributeCb(attribute);
      }
    });
  });
}

/**
 * Iterates on the attributes of the group and awaits the iterator
 *
 * @param groups - groups of attributes
 * @param attributeCb - the attribute callback
 */
export async function iterateAttrGroupAsync(
  groups: Pick<Form.AttributeGroup, 'attributes'>[] | null,
  attributeCb: (attr: Form.Attribute) => void | Promise<void>
): Promise<void> {
  for (const group of groups ?? []) {
    for (const attribute of group.attributes ?? []) {
      if (attribute) {
        await attributeCb(attribute);
      }
    }
  }
}

/**
 * Iterate a form and launch callback on attributes.
 * @param form - Form to iterate
 * @param attributeCb - Callback function with attribute parameter
 */
export function iterateForm(
  form: IterableForm | null,
  attributeCb: (attr: Form.Attribute, isInput: boolean) => void
): void {
  if (!form) {
    return;
  }

  // Iterate on form input groups
  iterateAttrGroup(form.inputGroups, (attr) => attributeCb(attr, true));

  // Iterate on form attribute groups
  iterateAttrGroup(form.attributeGroups, (attr) => attributeCb(attr, false));
}

/**
 * Manage update on current attribute alias (attribute change).
 * @param form - Form object with attribute groups
 * @param attribute - the attribute for whom to update *for*
 * @param actionCb - Function to execute on the attributes that react to the current attribute change (take an attribute alias in parameter)
 * @param managedAliases - Array of attribute aliases already managed (prevent infinite loop)
 */
export function updateOn(
  form: IterableForm | null,
  attribute: Form.Attribute,
  actionCb: (attr: Form.Attribute) => void,
  managedAliases: string[] = []
): void {
  if (managedAliases.includes(attribute.alias)) {
    return;
  }

  managedAliases.push(attribute.alias);
  if (!form) {
    return;
  }

  iterateForm(form, (iterateAttr) => {
    // Action on attribute that update on current alias and not already managed (+ call recursively)
    if (
      iterateAttr.updateOn != null &&
      iterateAttr.updateOn.length > 0 &&
      iterateAttr.updateOn.includes(attribute.alias) &&
      !managedAliases.includes(iterateAttr.alias)
    ) {
      // Launch callback for attribute alias found
      actionCb(iterateAttr);

      // Call updateOn for current attribute change
      updateOn(form, iterateAttr, actionCb, managedAliases);
    }
  });
}

/**
 * Iterates the column attributes of the table. Invokes `actionCb` for the attributes
 * whose `updateOn` includes the alias of `currentAttr`.
 * Also launches `updateOnTable` on the found attributes
 *
 * @param tableAttr - the table attribute
 * @param actionCb - Callback that receives the attribute and the column index that should be updated
 * @param currentAttr - the attribute for whom to update *for*
 * @param managedIds - Array of attribute itemIds for whom NOT to invoke the callback
 */
export function updateOnTable(
  tableAttr: Form.Attribute,
  actionCb: (colAttr: Form.Attribute, colIndex: number) => void,
  currentAttr: Form.Attribute,
  managedIds: string[] = []
): void {
  tableAttr.options?.displayOptions?.tableOptions?.columns?.forEach((column, colIndex) => {
    const iterateAttr = column.attribute;
    if (!iterateAttr) {
      return;
    }
    // Action on attribute that update on current itemId and not already managed (+ call recursively)
    if (
      iterateAttr.updateOn != null &&
      iterateAttr.updateOn.length > 0 &&
      iterateAttr.updateOn.includes(currentAttr.alias) &&
      !managedIds.includes(iterateAttr.itemId)
    ) {
      // Launch callback for attribute alias found
      actionCb(iterateAttr, colIndex);
      managedIds.push(iterateAttr.itemId);

      // Call updateOnTable for current attribute change
      updateOnTable(tableAttr, actionCb, currentAttr, managedIds);
    }
  });
}

/**
 * Get default value from the attribute parameter
 * @param attribute - Attribute
 * @returns the attribute default value
 */
export function getAttributeDefaultValue(attribute: Form.Attribute): unknown {
  if (attribute == null || attribute.defaultValue == null) {
    return null;
  }

  let defaultValue = attribute.defaultValue;

  // TODO: evaluate 'defaultValue' with new eval when implemented
  if (typeof defaultValue === 'string') {
    // For now, extract the expression from "$X{expr}" and evaluate it
    const res = /^\$X\{(.+)\}$/.exec(defaultValue.trim());
    if (res && res[1]) {
      defaultValue = LesserEval.evalExpression(
        res[1],
        {
          data: {},
          types: {},
          pagination: { pageIndex: 0, pageNumber: 0, pageCount: 0 },
        },
        null,
        null
      );
    }
  }

  switch (attribute.type) {
    // At this point, defaultValue should be a string
    case AttributeTypes.DATE: {
      if (attribute.options && attribute.options.showTime) {
        defaultValue = Vue.filter('isoDateFull')(defaultValue);
      } else {
        defaultValue = Vue.filter('isoDate')(defaultValue);
      }
      break;
    }
    case AttributeTypes.LINKS: {
      // At this point, defaultValue should be a string (alias) or array (of aliases or option objects)
      if (defaultValue) {
        // Ensure value is an array
        defaultValue = ensureArray(defaultValue);

        // Ensure the correct amount of options are selected
        if (defaultValue.length) {
          defaultValue =
            attribute.options && attribute.options.multiSelect === true
              ? defaultValue
              : [defaultValue[0]];
        }
      } else {
        defaultValue = [];
      }
      break;
    }
    case AttributeTypes.STRING: {
      // At this point, defaultValue should be a string
      if (attribute.options.multiple) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        defaultValue = defaultValue ? JSON.parse(defaultValue) : [];
      }
      break;
    }
  }

  return defaultValue;
}

/**
 * @param value - value to convert
 * @returns number or `NaN` if couldn't be converted
 */
export function parseNumericValue(value: unknown): number {
  return Number(value);
}

/**
 * Return a normalized value for a form attribute
 *
 * TODO: Refactor for all types and transform that to a static class.
 * @param alias
 * @param value
 * @param attribute - the attribute of the alias
 * @param fallbackType - the type of the attribute alias to use if attribute is unknown
 * @returns Normalized value
 */
export function getNormalizedValue(
  value: any,
  attribute: Form.Attribute | null,
  fallbackType: AttributeTypes | null
): any {
  const normalizers: { [x: string]: (value: any, attr: Form.Attribute | null) => any } = {
    Date: (value, attr) => {
      if (attr && attr.options.showTime) {
        return (Vue.filter('isoDateFull') as (arg: unknown) => string)(value);
      }
      return (Vue.filter('isoDate') as (arg: unknown) => string)(value);
    },
  };

  // Get alias type
  const myType = attribute?.type ?? fallbackType ?? null;
  const myAttribute = attribute ?? null;

  // Normalize value
  if (myType) {
    const normalizer = normalizers[myType];
    if (normalizer && value) {
      return normalizer(value, myAttribute);
    }
  }
  return value;
}

export function identifiersToLinkOptions(
  value: unknown,
  options: AttributeValues.AttributeOptionValue[]
): AttributeValues.AttributeOptionValue[] {
  if (typeof value === 'undefined' || !value) {
    return [];
  }

  const _value = ensureArray(value as string | AttributeValues.AttributeOptionValue);

  const flatOptions = options.flatMap((opt) => {
    if (opt.children && Array.isArray(opt.children)) {
      return [opt, ...opt.children];
    }
    return opt;
  });

  const result: AttributeValues.AttributeOptionValue[] = [];
  _value.forEach((val) => {
    const link = flatOptions.find((v) => {
      // Check value directly when is string
      if (typeof val === 'string') {
        return (
          (typeof v.alias === 'string' && v.alias === val) ||
          (typeof v.itemId === 'string' && v.itemId === val)
        );
      }

      // Otherwise object is a { itemId: string; alias: string }
      return (
        (typeof v.alias === 'string' && typeof val.alias === 'string' && v.alias === val.alias) ||
        (typeof v.itemId === 'string' && typeof val.itemId === 'string' && v.itemId === val.itemId)
      );
    });
    if (link) {
      result.push(link);
    }
  });

  return result;
}

export function linkOptionsToIdentifiers(value: unknown): string | string[] {
  if (
    value &&
    typeof value === 'object' &&
    !Array.isArray(value) &&
    Object.hasOwnProperty.call(value, 'alias')
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (value as any).alias;
  } else if (value && Array.isArray(value) && value.length && !Array.isArray(value[0])) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return value.map((el) => el.alias);
  }

  return value as string;
}

/**
 * Converts query to form values
 *
 * @param query - the query object
 * @param attributeGroups - all attribute groups in the form
 * @returns the form values
 */
export function queryToFormValues(
  query: Route['query'],
  attributeGroups: Pick<Form.AttributeGroup, 'attributes'>[]
): Record<string, AttributeValues.AttributeValue> {
  const urlValues: Record<string, AttributeValues.AttributeValue> = {};

  const attributesMap = {} as Record<string, Form.Attribute>;
  iterateAttrGroup(attributeGroups, (attr) => {
    attributesMap[attr.alias] = attr;
  });

  for (const alias of Object.keys(query)) {
    if (attributesMap[alias]) {
      const value = getFormUrlQuery(query, alias);
      if (value !== null) {
        urlValues[alias] = value;
      }
    }
  }

  return urlValues;
}

/**
 * Gets a form query key value
 *
 * @param query - the query object
 * @param key - the key to get
 * @returns the query key value
 */
export function getFormUrlQuery(query: Route['query'], key: string): string | null {
  const value = query[key];
  if (typeof value === 'string') {
    // Remove quotes prefix+suffix
    return value.substring(1, query[key].length - 1);
  }

  return null;
}
