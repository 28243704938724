import Router, { isNavigationFailure, NavigationFailureType } from 'vue-router';

import services from '../services';
import store from '../store';

/**
 * Load user infos request
 */
let loadUserInfosReq = null;

/**
 * Unknown route name (for tracking).
 */
const UNKNOWN_ROUTE_NAME = 'Unknown';

const router = new Router({
  mode: 'hash',
  routes: [],
});

/**
 * Before each route hook (guard).
 * @param {Object} to : the target Route Object being navigated to
 * @param {Object} from : the current route being navigated away from
 * @param {Function} next : this function must be called to resolve the hook
 */
router.beforeEach((to, from, next) => {
  // Add guardroute for each routes
  guardRoute(to, from, next);
});

/**
 * After each route hook.
 * @param {Object} route : the target Route Object being navigated to
 */
router.afterEach((route) => {
  // Track page (stop)
  const routeName = route.name || UNKNOWN_ROUTE_NAME;
  services.getService('analytics').stopTrackPage(routeName, route.fullPath);
});

/**
 * GuardRoute method to verify authentication and permissions.
 * @param {Object} to : the target Route Object being navigated to
 * @param {Object} from : the current route being navigated away from
 * @param {Function} next : this function must be called to resolve the hook
 */
async function guardRoute(to, from, next) {
  const auth = store.state.auth;
  const isPublicRoute = !!to.meta && !!to.meta.publicRoute;

  // If the page is NOT a public route: verify authentication
  if (!isPublicRoute && !auth.isLoggedIn) {
    // Get user informations to verify if user is logged with external authentication.
    if (!loadUserInfosReq) {
      // Simple dedupe for the request : on app mount adding multiple routes
      // (from added modules) provokes multiple navigations to the current route
      // Load user infos and do NOT redirect to login automatically on error: we handle it
      // ourselves below by aborting the current navigation
      loadUserInfosReq = services.getService('auth').loadUserInfos(false);
    }

    const res = await loadUserInfosReq;
    loadUserInfosReq = null;

    if (!res.success) {
      // If could not get user, abort this navigation and go to login instead
      return next({
        name: 'login',
        query: to.fullPath.startsWith('/login') ? null : { redirect: to.fullPath },
      });
    }
  }

  // Verify permission
  if (
    to.meta.permission &&
    services.getService('auth').hasPermission(to.meta.permission) === false
  ) {
    // Verify permission : permission denied
    const fail = to.meta.fail || '/404';
    return next(fail);
  }

  // Track page (start)
  const routeName = to.name || UNKNOWN_ROUTE_NAME;
  services.getService('analytics').startTrackPage(routeName);

  return next();
}

/**
 * Rethrows errors that are NOT navigation duplicated errors
 *
 * @param {*} err - an error
 */
export function ignoreNavigationDuplicatedErrorHandler(err) {
  if (!isNavigationFailure(err, NavigationFailureType.duplicated)) {
    throw err;
  }
}

export default router;
